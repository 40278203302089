// next
import { useRouter } from 'next/router'
import Link from 'next/link'
// react
import { useEffect, useMemo, useState } from 'react'
import { useFlag, useFlagsStatus } from '@unleash/nextjs'
// antd
import { Image, Menu, MenuProps, Space, Tag, Typography } from 'antd'

import A8MainnetButton from '../a8-mainnet/A8MainnetButton'
import { notify } from '@/providers/notify.provider'
// hooks
import useIsMobile from '@/hooks/useIsMobile'
import { useSeasons } from '@/hooks/leaderboard/useSeasons'

import { LeaderboardService } from '@/services/leaderboard/leaderboard'

import configs from '@/configs'
// constants
import { ROUTES } from '@/constants/routes'
import { FeatureFlag } from '@/constants/feature-flags'
import { MarketplaceTabs } from '@/constants/marketplace'

import { ItemType } from 'antd/es/menu/hooks/useItems'
import { SeasonStage } from '@/types/leaderboard.type'

import a8MainnetLdbBtnImg from '@/assets/images/systems/button/a8-mainnet-ldb-btn.webp'

// styles
import './index.scss'

type LeaderboardItemWithChildrenType = ItemType & {
  children?: ItemType[]
}

// TODO: Get dynamic pathname
const getPath = (pathname: string) => {
  const regex = '^/[^/]+'
  const matches = pathname.match(regex)

  return matches ? matches[0] : '/'
}

function MenuNav() {
  const [selectedKeys, setSelectedKeys] = useState<MenuProps['selectedKeys']>()
  const router = useRouter()
  const isMobile = useIsMobile()
  const { seasons, setSeasons } = useSeasons()
  const { pathname, query } = router

  const isLeaderboardEnabled = useFlag(FeatureFlag.Leaderboard)
  const isGachaEnabled = useFlag(FeatureFlag.Gacha)
  const isA8MainnetEnabled = useFlag(FeatureFlag.A8Mainnet)
  const isA8AirdropEnabled = useFlag(FeatureFlag.A8Airdrop)
  const isLaunchpadEnabled = useFlag(FeatureFlag.Launchpad)
  const isEliteNftEnabled = useFlag(FeatureFlag.EliteNft)
  const { flagsReady } = useFlagsStatus()

  useEffect(() => {
    let key = getPath(pathname)

    if (query?.tab && query.tab === MarketplaceTabs.Gacha) {
      key += `?tab=${MarketplaceTabs.Gacha}`
    }

    setSelectedKeys([key])
  }, [pathname, query])

  useEffect(() => {
    LeaderboardService.getSeasons({
      limit: 2,
      stages: [SeasonStage.Live, SeasonStage.Archived],
    })
      .then((seasons) => {
        setSeasons(seasons.data)
      })
      .catch((error) => notify.error({ message: error.message ?? error }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const menuItem: ItemType[] = useMemo(() => {
    const menuItems: ItemType[] = [
      {
        label: <Link href={{ pathname: ROUTES.MISSION.INDEX }}>Missions</Link>,
        key: ROUTES.MISSION.INDEX,
      },
      {
        label: (
          <Link
            href={{
              pathname: ROUTES.REWARD_CENTER.INDEX,
              query: {
                tab: MarketplaceTabs.All,
                page: '1',
              },
            }}
          >
            Rewards Center
          </Link>
        ),
        key: ROUTES.REWARD_CENTER.INDEX,
      },
      {
        label: <Link href={{ pathname: ROUTES.COMMUNITY.INDEX }}>Games</Link>,
        key: ROUTES.COMMUNITY.INDEX,
      },
    ]

    if (!flagsReady) return menuItems

    if (isGachaEnabled) {
      menuItems.push({
        label: (
          <Link
            href={{
              pathname: ROUTES.REWARD_CENTER.INDEX,
              query: {
                tab: MarketplaceTabs.Gacha,
                page: '1',
              },
            }}
          >
            Gacha
          </Link>
        ),
        key: `${ROUTES.REWARD_CENTER.INDEX}?tab=${MarketplaceTabs.Gacha}`,
      })
    }

    if (isLaunchpadEnabled) {
      menuItems.push({
        label: (
          <Link href={{ pathname: configs.location.launchpad }}>Launchpad</Link>
        ),
        key: configs.location.launchpad,
      })
    }

    if (isEliteNftEnabled) {
      menuItems.push({
        label: (
          <Link href={{ pathname: configs.location.eliteNft }}>Burn NFT</Link>
        ),
        key: configs.location.eliteNft,
      })
    }

    if (isLeaderboardEnabled) {
      const leaderboardMenuItem: LeaderboardItemWithChildrenType = {
        label: (
          <Link
            href={{ pathname: ROUTES.LEADERBOARD.INDEX }}
            style={{ color: 'white' }}
          >
            Leaderboard
          </Link>
        ),
        key: ROUTES.LEADERBOARD.INDEX,
        className: 'leaderboard-nav',
      }
      if (!isMobile) {
        leaderboardMenuItem.children = Object.values(seasons || {}).map(
          (season) => ({
            label: (
              <Link
                href={{
                  pathname: ROUTES.LEADERBOARD.DETAIL,
                  query: { slug: season.slug },
                }}
              >
                <Space
                  key={season._id}
                  className={`${season.stage === SeasonStage.Live && 'active'}`}
                >
                  <Typography.Text className="nav-desktop--menu">
                    {season.name.split('|').join(' ')}
                  </Typography.Text>
                  {season.stage === SeasonStage.Live ? (
                    <Tag color="#0FDBD126">
                      <Typography.Text
                        type="success"
                        style={{ fontStretch: 'expanded', fontSize: 12 }}
                      >
                        Live
                      </Typography.Text>
                    </Tag>
                  ) : (
                    <Tag color="#DB464630">
                      <Typography.Text
                        style={{
                          color: '#DB4646',
                          fontStretch: 'expanded',
                          fontSize: 12,
                        }}
                      >
                        Ended
                      </Typography.Text>
                    </Tag>
                  )}
                </Space>
              </Link>
            ),
            key: ROUTES.LEADERBOARD.INDEX + '/' + season.slug,
          }),
        )
      }

      menuItems.push(leaderboardMenuItem)
    }

    if (isA8MainnetEnabled) {
      menuItems.push({
        label: (
          <Link href={ROUTES.A8_LAYER_2.INDEX} className="!block">
            <A8MainnetButton
              className="a8-mainnet--btn-item disable-active !h-[40px]"
              label="Mainnet"
            />
          </Link>
        ),
        key: ROUTES.A8_LAYER_2.INDEX,
      })
    }

    if (isA8AirdropEnabled) {
      menuItems.push({
        label: (
          <Link href={ROUTES.A8_AIRDROP.INDEX} className="!block">
            <Image
              className="disable-active"
              src={a8MainnetLdbBtnImg.src}
              alt="a8-mainnet-ldb-btn"
              width={117}
              height={38.5}
              preview={false}
            />
          </Link>
        ),
        key: ROUTES.A8_AIRDROP.INDEX,
      })
    }

    return menuItems
  }, [
    flagsReady,
    isGachaEnabled,
    isMobile,
    isLaunchpadEnabled,
    isLeaderboardEnabled,
    isA8MainnetEnabled,
    isA8AirdropEnabled,
    isEliteNftEnabled,
    seasons,
  ])

  if (isMobile)
    return (
      <Menu
        mode="vertical"
        items={menuItem}
        className="header--menu-nav--mobile"
        selectedKeys={selectedKeys}
      />
    )

  return (
    <Menu
      mode="horizontal"
      items={menuItem}
      className="header--menu-nav--desktop"
      selectedKeys={selectedKeys}
      rootClassName="nav-desktop"
    />
  )
}

export default MenuNav
